<template>
    <div class="page-wrap">
        <div class="content-box">
            <span class="num">404</span>
            <p class="info">页面不存在！请点击 <a href="javascript:;" class="flush" @click="handleClick">刷新</a></p>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'

export default {
    name: 'Error404',
    data() {
        return {
            lock: false
        };
    },
    created () {

    },
    methods: {
        ...common,
        handleClick() {
            if (this.lock) {
                return;
            }
            this.lock = true;
            setTimeout(() => {
                this.$router.replace('/login');
            }, 300);
        }
    }
}
</script>

<style scoped>
.page-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #55a0d7;
}
.content-box {
    width: 400px;
    height: 300px;
    position: absolute;
    top: 45%;
    left: 50%;
    margin: -150px 0 0 -200px;
    text-align: center;
}
.content-box .num {
    font-size: 130px;
    color: #fff;
   /* text-shadow: #000 4px 0 0, #000 0 4px 0, #000 -4px 0 0, #000 0 -4px 0;*/
    font-family:Arial, Helvetica, sans-serif;
}
.info {
    color: #fff;
    font-size: 16px;
}
.flush {
    color:rgb(23, 62, 204);
    text-decoration: none;
}
.flush:hover {
    color:rgb(75, 113, 253);
    text-decoration: none;
}
</style>
